@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}

h1 {
  @apply font-magallanes text-4xl font-bold md:text-6xl;
}

h2 {
  @apply text-2xl font-bold md:text-4xl;
}

h3 {
  @apply text-xl font-bold md:text-3xl;
}

h4 {
  @apply text-lg font-bold md:text-2xl;
}

label {
  @apply mb-2 font-medium;
}

.content ol {
  list-style: decimal !important;
  @apply mb-2 px-3 md:px-6;
}

.content ul {
  list-style: disc !important;
  @apply mb-2 px-4 md:px-6;
}

.content p {
  @apply mb-6 text-pretty;
}

.content a:not(.btn) {
  @apply text-blue-600 underline hover:text-blue-500;
}

.content h1 {
  @apply text-balance font-montserrat text-4xl font-bold md:text-6xl;
}

.content h3 {
  @apply mb-8 text-balance;
}

.content h2 {
  @apply mb-4 text-balance;
}

.content div {
  @apply text-pretty;
}

.slick-slide div {
  @apply !outline-none;
}

.slick-slider .slick-dots {
  @apply !-bottom-7 !flex justify-center;
}

.gallery .slick-slider .slick-dots {
  @apply !-bottom-5;
}

@media (min-width: 1024px) {
  .slick-slider .slick-dots {
    @apply !-bottom-11;
  }

  .gallery .slick-slider .slick-dots {
    @apply !-bottom-6;
  }
}

.slick-slider .slick-dots li {
  @apply m-0 h-6 w-6;
}

.slick-slider .slick-dots li button:before {
  @apply flex h-6 w-6 items-center justify-center;
}

.react-datepicker {
  @apply border-none shadow-xl;
}

.react-datepicker__header {
  @apply border-b-gray-200 bg-transparent;
}

.react-datepicker-wrapper {
  @apply order-2 h-full flex-1;
}

.react-datepicker__input-container {
  @apply flex h-full w-full flex-1;
}

.react-datepicker__day,
.react-datepicker__day:hover {
  @apply rounded-full;
}

.react-datepicker__day--selected {
  @apply bg-blue-600;
}

.react-datepicker__triangle {
  @apply hidden;
}
